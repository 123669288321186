<template>
<div>
  <van-cell-group title="管理" v-if="whale.user.validate('/meet/info')">
    <van-cell title="开新会议" is-link size="large" icon="star-o" to="/meet/edit/info" />
    <van-cell title="管理会议" is-link size="large" icon="edit" to="/meet/edit" />
  </van-cell-group>
  <van-cell-group title="查询">
    <van-cell title="近期会议" is-link size="large" icon="hot-o" to="/meet/query?dur=RECENT" />
    <van-cell title="往期会议" is-link size="large" icon="replay" to="/meet/query?dur=HIS" />
  </van-cell-group>
</div>
</template>

<script>
export default {
  name: "index"
}
</script>
<style scoped></style>